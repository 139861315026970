body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}
a:active,
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0!important;
}
.width100 {
  width: 100%!important;
  display: block;
}
.height100 {
  height: 100%;
}
.v-center {
  float: none;
  display: inline-block;
  vertical-align: middle;
}
.v-bottom {
  float: none;
  display: inline-block;
  vertical-align: bottom;
}
@media (max-width: 768px) {
}
.no-outline {
  outline: 0 !important;
}
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 .col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9 .col-xxl-10,
.col-xxl-11,
.col-xxl-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 1400px) {
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .hidden-xl {
    display: none!important;
  }
  .visible-xl {
    display: block!important;
  }
}
@media (min-width: 1400px) {
  .inline-block-xl {
    display: inline-block !important;
  }
}
#packageFilter.in,
#packageFilter.collapsing {
  display: block!important;
}
@media (max-width: 991px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 8px;
    padding-left: 8px;
  }
  .row {
    margin-right: -6px;
    margin-left: -6px;
  }
}
@media (min-width: 992px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  -webkit-flex: 0 0 8.333333%;
          flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -webkit-flex: 0 0 16.666667%;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -webkit-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -webkit-flex: 0 0 41.666667%;
          flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -webkit-flex: 0 0 58.333333%;
          flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -webkit-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -webkit-flex: 0 0 83.333333%;
          flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -webkit-flex: 0 0 91.666667%;
          flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
body {
  position: relative;
  overflow-x: hidden;
}
.header {
  position: relative;
}
.header .headerImage {
  width: 100%;
  height: 400px;
  background-image: url(/static/media/header.949b3b8d.jpg);
  background-position: top;
  background-size: cover;
}
@media (min-width: 992px) {
  .header .headerImage {
    height: 600px;
    background-position: center;
  }
}
@media lg {
  .header .headerImage {
    background-position: center;
  }
}
.headerLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: auto;
  margin-left: -50px;
  margin-top: -50px;
}
@media (min-width: 768px) {
  .headerLogo {
    width: 150px;
    margin-left: -75px;
    margin-top: -75px;
  }
}
.show {
  display: block;
}
.hide {
  display: none;
}
.headline {
  font-family: 'OpenSans Bold', sans-serif;
  font-size: 25px;
}
.headline.gallerie {
  margin-bottom: 20px;
}
.date {
  font-family: 'OpenSans Light', sans-serif;
  font-size: 18px;
}
.text {
  font-family: 'OpenSans Regular', sans-serif;
  font-size: 14px;
}
hr {
  margin: 5px 0px 10px;
  border-top: 1px solid black;
  width: 100px;
}
.postPhoto {
  height: auto;
  width: 100%;
}
button:focus {
  outline: none;
}
.post {
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .post {
    margin-bottom: 80px;
  }
}
.galleryImage {
  width: 100%;
  height: auto;
}
.photo {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .photo {
    height: 250px;
  }
}
@media (min-width: 1200px) {
  .photo {
    margin-bottom: 30px;
  }
}
.pswp-gallery {
  width: 100%;
}
.pswp-gallery .pswp-thumbnail {
  width: 50%;
  float: left;
}
@media (min-width: 768px) {
  .pswp-gallery .pswp-thumbnail {
    width: 25%;
  }
}
.post .pswp-thumbnail {
  width: 25% !important;
  float: left;
}
@media (min-width: 768px) {
  .post .pswp-thumbnail {
    width: 25%;
  }
}
a:not([href]):not([tabindex]) {
  color: white !important;
  text-shadow: none;
}
.gallery .lightBoxGallery-thumbnails img {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  padding: 5px !important;
}
.post .pswp-gallery .pswp-thumbnail:first-child {
  position: relative !important;
  width: 100% !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  -webkit-flex: 0 0 100% !important;
          flex: 0 0 100% !important;
  max-width: 100% !important;
  padding: 0px 0px !important;
}
.post .lightBoxGallery-thumbnails img {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  padding: 5px !important;
}
.pswp-thumbnail {
  cursor: pointer;
}
@media (min-width: 576px) {
  .gallery .lightBoxGallery-thumbnails img {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .gallery .lightBoxGallery-thumbnails img {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 992px) {
  .lightBoxGallery-thumbnails img {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
}
.absatz {
  margin-top: 15px;
}
.moreBtn {
  display: block !important;
  margin-top: 15px !important;
  border-color: #7da651 !important;
  color: #7da651 !important;
}
.imageWrapper {
  padding: 5px !important;
}
.galleryBorderPadding {
  padding: 0px 5px;
}
#content {
  z-index: 3;
  position: relative;
  padding-bottom: 80px;
  min-height: 100vh;
  overflow-x: hidden;
}
.logo_in_nav {
  background-image: url(/static/media/logo_red.1373a8b9.png);
  width: 68px;
  height: 68px;
  position: absolute;
  left: 50%;
  margin-left: -32px;
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 200;
}
.navBar {
  position: relative;
  z-index: 2;
  height: 68px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center top;
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.nav-elem {
  color: #ffffff;
  letter-spacing: 0.5px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 15px;
  padding-left: 50px;
  cursor: pointer;
  width: 90%;
  height: 68px;
  line-height: 50px;
  float: right;
  display: inline-block;
  padding: .5em 1.1em;
  background-clip: padding-box;
  transition: background-color 0.15s;
  background-color: #A0D468;
}
.nav-elem.active {
  font-weight: 700;
  background-color: #7da651;
}
.nav-elem:hover {
  background-color: #7da651;
  transition: background-color 0.35s;
}
@media (min-width: 768px) {
  .nav-elem {
    width: 250px;
    text-align: center !important;
  }
}
.text-right {
  background-image: url(/static/media/triangle.d1db0259.svg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  background-color: #A0D468;
  text-align: right;
  float: left;
  padding-right: 50px;
}
@media (min-width: 768px) {
  .text-right {
    float: right;
    margin-right: 80px;
    padding-right: 1.1em;
    background: url(/static/media/triangle.d1db0259.svg) no-repeat right top / contain, url(/static/media/triangle-links.835eaae0.svg) no-repeat left top / contain;
    background-color: #A0D468;
  }
}
.text-left {
  background-image: url(/static/media/triangle-links.835eaae0.svg);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  background-color: #A0D468;
  padding-left: 50px;
}
@media (min-width: 768px) {
  .text-left {
    float: left;
    margin-left: 80px;
    text-align: center !important;
    padding-left: 1.1em;
    background: url(/static/media/triangle.d1db0259.svg) no-repeat right top / contain, url(/static/media/triangle-links.835eaae0.svg) no-repeat left top / contain;
    background-color: #A0D468;
  }
}
.footer {
  background-color: #A0D468;
  color: white;
  text-align: center;
  height: 70px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  line-height: 70px;
  margin-top: 80px;
}
@font-face {
  font-family: 'bb';
  src: url(/static/media/balibeach.783d721c.otf) format('otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gentium Book Basic Regular';
  src: url(/static/media/GenBkBasR.3430cc82.ttf);
  /*src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
      */
}
@font-face {
  font-family: 'Gentium Book Basic Bold';
  src: url(/static/media/GenBkBasB.82bd37e4.ttf);
  /*src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
      */
}
@font-face {
  font-family: 'OpenSans Regular';
  src: url(/static/media/OpenSans-Regular.d7d5d458.ttf);
  /*src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
      */
}
@font-face {
  font-family: 'OpenSans Light';
  src: url(/static/media/OpenSans-Light.9ff12f69.ttf);
  /*src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
      */
}
@font-face {
  font-family: 'OpenSans Bold';
  src: url(/static/media/OpenSans-Bold.f5331cb6.ttf);
  /*src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
      */
}

